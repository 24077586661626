import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import styled from 'styled-components';

import { BackgroundProps } from '../types';

const StyledBackground = styled(BackgroundImage)`
  background-position: initial !important;
  height: 100%;
  /* @media only screen and (min-width: 1100px) {
    height: 100vh;
  } */
  &::before,
  &::after {
    position: fixed !important;
  }
`

const Background = ({ children }: BackgroundProps) => {
  const data = useStaticQuery(
    graphql`
      query {
        desktop: file(relativePath: { eq: "landing.jpeg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )
  const imageData = data.desktop.childImageSharp.fluid

  return (
    <StyledBackground fluid={imageData}>
      {children}
    </StyledBackground>
  )
}

export default Background;