import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import { ImageProps } from '../types';

type Edge = { node: { name: string | any[] } };


const Image = ({ fileName, alt, className }: ImageProps): JSX.Element => (
  <StaticQuery
    query={graphql`
      query ImageQuery {
        images: allFile(filter: { extension: { regex: "/(jpeg|jpg|png)/" } }) {
          edges {
            node {
              name
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
              }
            }
          }
        }
      }
    `}
    render={(data) => {
      const image = data.images.edges.find((n: Edge) => n.node.name === fileName);
      if (!image) {
        return null;
      }

      const { gatsbyImageData } = image.node.childImageSharp;

      return (
        <GatsbyImage alt={alt} image={gatsbyImageData} className={className} />
      );
    }}
  />
);

Image.defaultProps = {
  className: null,
};

export default Image;
