import { createGlobalStyle } from 'styled-components';
 
const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    height: 100vh;
    color: white;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";;
    overscroll-behavior: none;
  }
  a {
    color: white;
    text-decoration: none;
    &:visited {
      color: white;
    }
  }
  p {
    line-height: 1.5;
  }
  div {
    box-sizing: border-box;
  }
  li {
    line-height: 1.5;
  }
  #___gatsby {
    height: 100vh;
  }
  #gatsby-focus-wrapper {
    height: 100vh;
  }
  #mce-responses a {
    color: #007BFF;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`;
 
export default GlobalStyle;