import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import Image from './Image';
import { HeaderProps } from '../types';

const Wrapper = styled.header`
  display: flex;
  justify-content: space-between;
`;

const StyledLink = styled(Link)`
  display: inline-block;
  padding: 0.3215rem;
`;

const StyledImage = styled(Image)`
  width: 6rem;
`;

export function Header({ logoLink, logoAlt }: HeaderProps ): JSX.Element {
  return (
    <Wrapper>
      <StyledLink to="/">
        <StyledImage fileName={logoLink} alt={logoAlt}/>
      </StyledLink>
    </Wrapper>
  );
}
